













































































































































import { Component, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import lsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { apiOtherResources, apiServiceFeeStatus, apiSetRates, apiSetStatus, } from '@/api/goods'
import { RequestPaging } from '@/utils/util'
@Component({
  components: {
    LsDialog,
    MaterialSelect,
    lsPagination,
  },
})
export default class OperationManagementIndex extends Vue {
  /** S Data **/
  identity: number | null = null // 当前编辑用户的身份ID
  apiGoodsPackgeLists = apiOtherResources
  pager: RequestPaging = new RequestPaging()

  dialogVisible: boolean = false
  build_form: object = {
    service_fee_status: '0', //运营包名称
    resource_name: '', //运营包名称
  }

  listsNopage = []

  searchObj: Object = {
    service_fee_status: '0',
    resource_name: '',
  }
  tmp_row: Object = {
    service_percentage: '',
    price: '',
  }
  pane_label: Object = {
    alreadySet: '0',
    notSet: '0',
  }

  async setServiceFee(){
    const res = await apiSetRates(this.tmp_row);
    this.dialogVisible = false
    this.getGoodsPackgeLists()
  }
  /** E Data **/
  async modifyServiceFee(row:any){
    this.dialogVisible = true;
    this.tmp_row = JSON.parse(JSON.stringify(row));
  }

  async chageStatus(row:any){
    const  res = await apiSetStatus(row);
    this.getGoodsPackgeLists()
  }
  // 获取运营包
  getGoodsPackgeLists(page?: number) {
    page && (this.pager.page = page);
    this.pager.request({
      callback: apiOtherResources,
      params: {
        ...this.searchObj,
      },
    })
  }



  handleClick () {
    this.getGoodsPackgeLists()
  }

  // 重置搜索
  reset (): void {
    Object.keys(this.searchObj).map((key) => {
      if (key != 'service_fee_status') {
        this.$set(this.searchObj, key, '')
      }

    })
    this.getGoodsPackgeLists()
  }

  created () {
    this.getGoodsPackgeLists()
  }
}
